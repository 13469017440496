
    @import "config.scss";
 
.breadcrumb {
  margin: 1vw;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 24px;
  .link {
    color: white;
  }
  .item {
    display: flex;
    gap: 10px;
  }
  .chevron {
    color: white;
  }
}
